table{
    margin: 48px auto;
    border-collapse: collapse;
}
.table-container{
    overflow-x: auto;

}

td,th{
    padding: 12px;
}

.fixed{
    position: sticky;
    left: 0;
    background-color: rgba(242, 245, 249);
    z-index: 2;
}

.update-btn{
    border:2px solid rgb(204, 112, 13);
    color: rgb(204, 112, 13);
    padding: 8px 12px;
    border-radius: 16px;
}

.update-btn:hover{
    background-color: rgb(204, 112, 13);
    color: white;
}

